/** change language button path according to language
 * @param {string} pathname current url string
 * @returns {string}
 */

export const changeLandingPath = (pathname: string, altLang: string) => {
  let targetUrl = pathname;
  const iskoJaUrl =
    targetUrl === '/ko' ||
    targetUrl === '/ja' ||
    targetUrl === '/cn' ||
    targetUrl === '/es' ||
    targetUrl.startsWith('/ko/') ||
    targetUrl.startsWith('/ja/') ||
    targetUrl.startsWith('/es/') ||
    targetUrl.startsWith('/cn/');
  if (iskoJaUrl) {
    const pathnameArr = pathname.slice(1).split('/');
    const engUrlWithoutSlash = pathnameArr.slice(1).join('/');
    targetUrl = engUrlWithoutSlash;
  }
  if (altLang === 'en') {
    return '/' + targetUrl;
  }

  if (pathname.startsWith('/private')) {
    return pathname;
  }
  return `/${altLang}${targetUrl ? `/${targetUrl}` : ''}`;
};
