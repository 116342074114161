import styled from 'styled-components';
import { COLOR } from 'constants/COLOR';

type LoaderProps = {
  size: number;
  border: number;
  tbSize?: number;
  tbBorder?: number;
  mbSize?: number;
  mbBorder?: number;
};

const Component = styled.div<LoaderProps>`
  margin: 0 auto;
  width: ${({ size }) => size && size}px;
  height: ${({ size }) => size && size}px;
  border-radius: 50%;
  background: conic-gradient(${COLOR.PRIMARY} 0% 12.8%, rgba(0, 0, 0, 0) 12.8% 100%);
  box-shadow: 0 0 0 ${({ border }) => border && border}px ${COLOR.PRIMARY} inset;
  animation: load 1.2s infinite linear;
  -webkit-animation: load 1.2s infinite linear;

  ${({ theme, tbSize, tbBorder }) =>
    theme.media.M(`
      ${
        tbSize &&
        tbBorder &&
        `
          width: ${tbSize}px;
          height: ${tbSize}px;
          box-shadow: 0 0 0 ${tbBorder}px ${COLOR.PRIMARY} inset;
      `
      }
  `)}

  ${({ theme, mbSize, mbBorder }) =>
    theme.media.S(`
      ${
        mbSize &&
        mbBorder &&
        `
          width: ${mbSize}px;
          height: ${mbSize}px;
          box-shadow: 0 0 0 ${mbBorder}px ${COLOR.PRIMARY} inset;
      `
      }
  `)}

  @-webkit-keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Loader = ({ size, border, tbSize, tbBorder, mbSize, mbBorder }: LoaderProps) => {
  return (
    <Component size={size} border={border} tbSize={tbSize} tbBorder={tbBorder} mbSize={mbSize} mbBorder={mbBorder} />
  );
};
