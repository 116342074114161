import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { useAtom } from 'jotai';
import langAtom from 'stores/langAtom';
import planStatusAtom from 'stores/planStatusAtom';
import authAtom from 'stores/authAtom';
import correctAtom, { emptyCorrect } from 'stores/correctAtom';
import cardStatusAtom, { emptyCardStatus } from 'stores/cardStatusAtom';
import { getBlogLink } from 'utils/getLangPath';

type MenuProps = {
  setShowMenu: (show: boolean) => void;
};

export function CommonMenu() {
  /* ============================== 0. Variables ============================== */
  // --------------------------------------------------------------------------
  // Atom
  // --------------------------------------------------------------------------
  const [planStatus] = useAtom(planStatusAtom);
  const [lang] = useAtom(langAtom);
  const [, setCorrect] = useAtom(correctAtom);
  const [, setCardStatus] = useAtom(cardStatusAtom);
  // --------------------------------------------------------------------------
  // Other
  // --------------------------------------------------------------------------
  const { t } = useTranslation('headerFooterMenu');
  const router = useRouter();
  const currentPath = router.pathname;
  const isPremiumUser = planStatus.status !== 'free';
  const isPlanCancelUser = isPremiumUser && planStatus.is_canceled;
  const isTrialUser = isPremiumUser && planStatus.is_trial;
  const isPromoUser = planStatus.plan === 'promo';
  const isButtonDisabled = isPromoUser || isPlanCancelUser || isTrialUser;

  const menuArray = [
    { src: '/images/ico-user.svg', href: '/account', label: t('menu.My account') },
    { src: '/images/ico-card.svg', href: '/plan', label: t('menu.Pricing') },
    { src: '/images/ico-promo.svg', href: '/promo', label: t('menu.Redeem code') },
    { src: '/images/ico-faq.svg', href: '/faq', label: 'FAQ' },
    {
      src: '/images/ico-blog.svg',
      href: getBlogLink(lang),
      label: t('menu.Blog'),
    },
    { src: '/images/ico-setting.svg', href: '/setting', label: t('menu.Setting') },
  ];

  const proofread = t('menu.Proofread');
  const paraphrasing = t('menu.Paraphrase');
  const translation = t('menu.Translation');

  // Style
  const headingContainerStyle = isPremiumUser
    ? 'pt-8 pb-24 px-24 bg-lowEmp'
    : 'pt-8 pb-16 px-24 bg-lightGray';

  const commonBtnStyle = 'bg-surface rounded-sm lg:rounded-md lg:text-bodyM';

  const listStyle = `w-full h-48 pl-24 flex items-center justify-start cursor-pointer
  md:h-56 md:px-24`;

  const listImgStyle = 'w-24 h-24 mr-8 md:mr-[10px]';

  const menuLabelStyle = (isActive: boolean) => {
    const textColorNWeightStyle = isActive
      ? 'text-black font-bold'
      : 'text-darkGray font-medium';
    return `text-bodyM ${textColorNWeightStyle}`;
  };
  /* ======================================================================== */

  /* ============================== 1. Hooks ============================== */

  /* ============================== 2. Functions ============================== */
  const emptyCorrectAndCardStatus = () => {
    setCardStatus(() => emptyCardStatus);
    setCorrect(() => emptyCorrect);
  };

  const handleClick = (targetPath: string) => {
    if (currentPath !== targetPath) {
      emptyCorrectAndCardStatus();
    }
  };

  const handleChangePlan = () => {
    if (planStatus?.next_plan && planStatus.next_plan === 'Monthly') {
      router.push('/plan/payment/v1?type=Annual');
    } else {
      router.push('/plan/payment/v1?type=Monthly');
    }
  };

  return (
    <>
      <div
        className={`w-full ${headingContainerStyle} md:p-24`}
      >
        {/* ============================================== */}
        <div className="flex items-center">
          <span className="font-bold text-bodyS">
            {t('menu.Subscription')}
          </span>
        </div>
        <div className="flex items-center justify-between mt-8">
          {!isPremiumUser ? (
            <>
              <div
                className="text-bodyM font-medium [&_.remainPlan]:text-gray"
              >
                <span className="text-black">{t('menu.Free')}</span>
              </div>
              <Link
                href="/plan"
                className={`btn btn-line ${commonBtnStyle} w-[124px] h-40 p-0`}
              >
                Go PREMIUM
              </Link>
            </>
          ) : (
            <>
              <div
                className="text-bodyM font-medium [&_.remainPlan]:text-gray"
              >
                <span className="text-dark">{t('menu.PREMIUM')}</span>
              </div>
              <button
                type="button"
                disabled={isButtonDisabled}
                onClick={handleChangePlan}
                className={`btn btn-line ${commonBtnStyle} w-fit h-40
                px-[16px] py-[12px] font-semibold`}
              >
                {t('menu.Update')}
              </button>
            </>
          )}
        </div>
      </div>
      {/* ============================================== */}
      <ul
        className="w-full pt-12 md:pt-0 md:border-b md:border-dividerDark"
      >
        <Link href="/proofread" passHref className="S_only">
          <li
            className={`${listStyle} ${menuLabelStyle(currentPath === '/proofread')}`}
            onClick={() => handleClick('/proofread')}
          >
            <img
              alt={proofread}
              src="/images/ico-proofread.svg"
              className={listImgStyle}
            />
            {proofread}
          </li>
        </Link>
        <Link href="/paraphrasing" passHref className="S_only">
          <li
            className={`${listStyle} ${menuLabelStyle(currentPath === '/paraphrasing')}`}
            onClick={() => handleClick('/paraphrasing')}
          >
            <img
              alt={paraphrasing}
              src="/images/ico-paraphrase.svg"
              className={listImgStyle}
            />
            {paraphrasing}
          </li>
        </Link>
        <Link href="/translation" passHref className="S_only">
          <li
            className={`${listStyle} ${menuLabelStyle(currentPath === '/translation')}`}
            onClick={() => handleClick('/translation')}
          >
            <img
              alt={translation}
              src="/images/ico-translate.svg"
              className={listImgStyle}
            />
            {translation}
          </li>
        </Link>
        {menuArray.map((item, idx) => {
          const isItemBlog = idx === 4;
          const isActive = currentPath === item.href;
          return !isItemBlog ? (
            <Link href={item.href} passHref key={item.href}>
              <li
                className={`${listStyle} ${menuLabelStyle(isActive)}`}
                onClick={() => handleClick(item.href)}
              >
                <img
                  alt={item.label}
                  src={item.src}
                  className={listImgStyle}
                />
                {item.label}
              </li>
            </Link>
          ) : (
            <a href={item.href} key={item.href}>
              <li
                className={`${listStyle} ${menuLabelStyle(isActive)}`}
                onClick={emptyCorrectAndCardStatus}
              >
                <img
                  alt={item.label}
                  src={item.src}
                  className={listImgStyle}
                />
                {item.label}
              </li>
            </a>
          );
        })}
      </ul>
    </>
  );
}

export const LogoutMenuBtn = ({ setShowMenu }: MenuProps) => {
  /* ============================== 0. Variables ============================== */
  // --------------------------------------------------------------------------
  // Atom
  // --------------------------------------------------------------------------
  const [, setCorrect] = useAtom(correctAtom);
  const [, setCardStatus] = useAtom(cardStatusAtom);
  const [auth, setAuth] = useAtom(authAtom);
  const [lang] = useAtom(langAtom);
  // --------------------------------------------------------------------------
  // Other
  // --------------------------------------------------------------------------
  const router = useRouter();
  const cookies = new Cookies();
  const { t } = useTranslation('headerFooterMenu');

  const afterLogoutUrl = lang === 'en' ? '/' : `/${lang}`;
  /* ======================================================================== */

  const handleLogout = () => {
    // add cookie change listener and add callback function that should execute
    // if token cookie has been deleted
    cookies.addChangeListener(({ name }: { name: string }) => {
      // functions that should be executed when token cookie has been deleted
      if (name === 'token' && cookies.get('token') === undefined) {
        setAuth(() => {
          return {
            ...auth,
            token: null,
            user: null,
          };
        });
        // close menu
        setShowMenu(false);
        // delete input contents residue
        setCorrect(emptyCorrect);
        // delete card format
        setCardStatus(emptyCardStatus);
        // delete axios header
        delete axios.defaults.headers.common.Authorization;
        // remove cookie listener
        cookies.removeChangeListener(() => {});
        return router.replace(afterLogoutUrl);
      }
    });

    // remove token cookie
    cookies.remove('token', { path: '/' });
    cookies.remove('default_product', { path: '/' });
  };

  return (
    <button
      type="button"
      onClick={handleLogout}
      className={`w-full h-56 mt-[14px] px-24 pb-32 flex items-center
        md:mt-0 md:pb-0`}
    >
      <img
        src="/images/ico-logout.svg"
        alt="sign out icon"
        className="w-24 h-24 mr-8 md:mr-[10px]"
      />
      <span className="font-medium text-bodyM text-gray">
        {t('menu.Sign out')}
      </span>
    </button>
  );
};
