/**
 * gets landing page's english page pathname that starts with '/', and langAtom value.
 * returns specific language version of enPath
 * @param enPath english version path name
 * @param currentLang current value of lang atom
 * @returns specific language version of enPath
 */
export const getLangPath = (enPath: string, currentLang: string) => {
  if (currentLang === 'en') {
    return enPath;
  } else if (enPath === '/') {
    return `/${currentLang}`;
  } else {
    return `/${currentLang}${enPath}`;
  }
};

export const getBlogLink = (currentLang: string) => {
  return `https://blog${(['en', 'es'].includes(currentLang)) ? '' : `-${currentLang}`}.engram.us/`;
};
