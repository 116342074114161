import { atom } from 'jotai';

export interface CardStatusType {
  ref: number[] | null;
  data: { [key: number]: 'accepted0' | 'accepted1' | 'default' | 'ignored' } | null;
}

export const emptyCardStatus = {
  ref: [],
  data: {},
};

export default atom<CardStatusType>(emptyCardStatus);
