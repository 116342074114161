import React from 'react';
import Cookies from 'universal-cookie';

type SwrContainerProps = {
  error: any;
  data: any;
  loadingIndicator?: React.ReactNode;
  errorView?: React.ReactNode;
  children?: any;
};

function DefaultError(error: any) {
  // del token from the cookie in case of the incorrect cookie error
  const cookies = new Cookies();
  cookies.remove('token');

  // todo. log correctly
  console.log(error);
  // todo. redirect page to error page or somewhre else - currently it redirects to the landing page (by default)
  return <></>;
}

function DefaultIndicator() {
  return (
    <div
      className={`w-full h-full max-w-full p-[30px] max-h-full relative
        flex justify-center items-center
        [&_div]:w-[200px] [&_div]:absolute [&_div]:opacity-30`}
    >
      <div className="dot-stage">
        <span className="dot-pulse" />
      </div>
    </div>
  );
}

export const SwrContainer = ({ error, data, loadingIndicator, errorView, children }: SwrContainerProps) => {
  if (error) return errorView || <DefaultError error={error} />;
  if (!data) return loadingIndicator || <DefaultIndicator />;
  return children;
};
