import { useAtom } from 'jotai';
import planStatusAtom from 'stores/planStatusAtom';

export const PlanStatusButton = () => {
  const [planStatus] = useAtom(planStatusAtom);

  return (
    planStatus?.status && planStatus.status !== 'free'
      ? (
        <button
          type="button"
          className={`w-[56px] h-[24px] ml-[8px] text-captionM font-semibold
            text-surface bg-darkGray rounded-sm
            md:w-[68px] md:text-captionL`}
        >
          PREMIUM
        </button>
      )
      : null
  );
};
