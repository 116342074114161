import { atom } from 'jotai';

export interface PlanStatusAtomType {
  is_canceled: boolean;
  is_trial: boolean;
  is_trial_used: boolean;
  login_type: string;
  name: string;
  email: string;
  pay_platform?: string;
  pg?: 'danal_tpay' | 'kakaopay';
  plan: 'Monthly' | 'Annual' | 'promo' | '';
  setting: {
    is_event_notification: boolean;
    lang: string;
    trans_lang: string;
    default_product: string;
    is_private: boolean;
    is_realtime: boolean;
  };
  status: 'free' | 'unlimited';
  today_limits: { proof: number; para: number; trans: number };
  unlimited_ended_at: string | null;
  next_plan?: 'Monthly' | 'Annual' | 'canceled';
}

export const emptyPlanStatus: PlanStatusAtomType = {
  is_canceled: false,
  is_trial: false,
  is_trial_used: false,
  login_type: '',
  name: '',
  email: '',
  plan: '',
  status: 'free',
  setting: {
    is_event_notification: true,
    lang: 'init',
    trans_lang: '',
    default_product: 'proofread',
    is_private: false,
    is_realtime: false,
  },
  today_limits: { proof: 0, para: 0, trans: 0 },
  unlimited_ended_at: null,
};

export default atom<PlanStatusAtomType>(emptyPlanStatus);
