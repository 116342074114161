import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAtom } from 'jotai';
import langAtom from 'stores/langAtom';
import { useRouter } from 'next/router';
import { langSeparateLandingArr, signedInArr } from 'constants/PATHNAME';
import { changeLandingPath } from 'utils/changeLandingPath';

export const LangSelect = () => {
  /* ============================== 0. Init ============================== */
  // --------------------------------------------------------------------------
  // Atom
  // --------------------------------------------------------------------------
  const [lang, setLang] = useAtom(langAtom);
  // --------------------------------------------------------------------------
  // State
  // --------------------------------------------------------------------------
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  // --------------------------------------------------------------------------
  // Ref
  // --------------------------------------------------------------------------
  const selectRef = useRef<HTMLButtonElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  // --------------------------------------------------------------------------
  // Etc
  // --------------------------------------------------------------------------
  const router = useRouter();
  const { asPath } = router;

  const options = [
    { value: 'ko', label: '한국어' },
    { value: 'en', label: 'English' },
    { value: 'ja', label: '日本語' },
    { value: 'cn', label: '简体中文' },
    { value: 'es', label: 'Español' }
  ];

  let currentLang = '';
  switch (lang) {
    case 'ko':
      currentLang = '한국어';
      break;
    case 'ja':
      currentLang = '日本語';
      break;
    case 'cn':
      currentLang = '简体中文';
      break;
    case 'es':
      currentLang = 'Español';
      break;
    default:
      currentLang = 'English';
      break;
  }

  const optionRenderArr = options.filter(item => item.value !== lang);
  // --------------------------------------------------------------------------
  // Style
  // --------------------------------------------------------------------------
  const selectBtnTopRadius = !isSelectOpen ? 'rounded-t-lg' : '';
  /* ============================== 1. Functions ============================== */
  // changes language and redirect if user selects a language from SelectLang
  const changeLanguage = async (altLang: string) => {
    if (altLang !== lang) {
      setIsSelectOpen(false);

      // setting language
      setLang(altLang);

      const redirectToEnVsContent = asPath.startsWith('/ko/vs') && (altLang === 'en' || altLang === 'ja');
      const redirectToKoVsContent = asPath.startsWith('/vs') && altLang === 'ko';

      if (langSeparateLandingArr.includes(asPath) || redirectToKoVsContent) {
        // redirect if user is on redirect landing page
        return router.push(changeLandingPath(asPath, altLang));
      } else if (redirectToEnVsContent) {
        // if user selects 'ja' on a vs content page, redirect to en vs content page.
        return router.push(changeLandingPath(asPath, 'en'));
      } else if (signedInArr.includes(asPath)) {
        // sending axios.patch if user has been logged in
        await axios.patch(`${process.env.API_HOST}/auth/v3/me`, { lang: altLang });
      } else {
        // do nothing if user is on no redirect landing page and return

      }
    }
  };

  /* ============================== 2. Hooks ============================== */
  useEffect(() => {
    const closeSelect = (e: MouseEvent): void => {
      if (
        dropDownRef.current
        && selectRef.current
        && !dropDownRef.current.contains(e.target as Node)
        && !selectRef.current.contains(e.target as Node)
      ) {
        setIsSelectOpen(false);
      }
    };
    document.addEventListener('mousedown', closeSelect);
    return () => {
      document.removeEventListener('mousedown', closeSelect);
    };
  }, [dropDownRef]);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsSelectOpen(current => !current)}
        ref={selectRef}
        className={`w-[120px] h-[40px] p-[2px] flex justify-between items-center
          border border-darkGray rounded-b-lg ${selectBtnTopRadius}`}
      >
        <img
          src="/images/ico-globe.svg"
          alt="black line icon of a globe"
          className="w-20 h-20"
        />
        <div
          className={`ml-[4px] text-bodyS font-medium text-center text-darkGray
            select-none lg:text-bodyM`}
        >
          {currentLang}
        </div>
        <img
          className="w-24 h-24"
          src={`/images/ico-arrow-${isSelectOpen ? 'drop' : 'fold'}.svg`}
          alt={isSelectOpen ? 'icon arrow drop' : 'icon arrow up'}
        />
      </button>
      {isSelectOpen && (
        <div
          ref={dropDownRef}
          className={`w-[120px] absolute ml-0 mt-[-124px] text-bodyS 
            text-darkGray bg-surface rounded-t-lg border border-darkGray
            overflow-hidden
            md:mt-[-200px] md:max-lg:right-24 lg:ml-[1112px] lg:text-bodyM
            `}
        >
          {optionRenderArr.map(item => (
            <button
              type="button"
              key={item.value}
              onClick={() => changeLanguage(item.value)}
              className={`w-full h-40 flex justify-center items-center
                cursor-pointer border-b border-darkGray
                hover:bg-primary hover:text-surface last-of-type:border-b-0`}
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
