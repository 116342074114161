import React, { useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import langAtom from 'stores/langAtom';
import { COLOR } from 'constants/COLOR';
import Link from 'next/link';

export type SideMenuProps = {
  activePage: string;
};

type ListProps = {
  active?: boolean;
};

const NavWrap = styled.div`
  width: 100vw;
  height: 66px;
  box-shadow: 0px -2px ${COLOR.DIVIDER_LIGHT} inset;
  overflow-x: auto;
  ${({ theme }) =>
    theme.media.S(`
    height: 48px;
  `)}
`;

const Component = styled.ul<ListProps>`
  width: fit-content;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: visible;
  &::-webkit-scrollbar {
    display: block;
    height: 1px;
  }
`;

const Menu = styled.menu<ListProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 156px;
  height: 100%;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  ${({ active }) => (active ? `border-bottom: 2px solid ${COLOR.PRIMARY};` : '')}
  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 66px;
    text-align: center;
    font-size: 16px;
    color: ${({ active }) => (active ? `color: ${COLOR.BLACK}; font-weight: bold;` : `color: ${COLOR.DARK_GRAY}`)};
  }
  ${({ theme }) =>
    theme.media.S(`
    width: 120px;
    height: 48px;
    margin-right: 0;
    a {
      font-size: 14px;
      line-height: 48px;
    }
`)}
`;

export const SubNavigation = ({ activePage }: SideMenuProps) => {
  // lastMenuRef designate last item of map method
  const lastMenuRef = useRef<any>();

  const { t } = useTranslation('headerFooterMenu');
  const [lang] = useAtom(langAtom);

  const menu = useMemo(
    () => [
      { menu: t('menu.My account'), link: '/account' },
      { menu: t('menu.Setting'), link: '/setting' },
      { menu: t('menu.Redeem code'), link: '/promo' },
      { menu: 'FAQ', link: '/faq' },
      { menu: t('menu.Contact Us'), link: '/support' },
    ],
    [lang],
  );

  useEffect(() => {
    // when x-scroll and click the last menu,
    // show last menu (do not go back to initial scroll status)
    if (lastMenuRef?.current?.firstChild?.href === window.location.href) {
      lastMenuRef.current.scrollIntoView({ block: 'nearest' });
    }
  }, []);

  return (
    <NavWrap>
      <Component>
        {menu?.map(item => (
          <Menu active={activePage === item.menu} key={item.menu} ref={lastMenuRef}>
            <Link
              href={item.link}
              target={item.link.startsWith('/') ? '_self' : '_blank'}
              dangerouslySetInnerHTML={{ __html: item.menu }}
            />
          </Menu>
        ))}
      </Component>
    </NavWrap>
  );
};
