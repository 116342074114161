import { useState, useEffect } from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { useAtom } from 'jotai';
import langAtom from 'stores/langAtom';
import { Icon as IconBase } from 'components/images';
import { getLangPath, getBlogLink } from 'utils/getLangPath';
import { LangSelect } from './buttons/LangSelect';

export const Footer = ({ pathLang }: { pathLang?: string }) => {
  /* ============================== 0. Init ============================== */
  // --------------------------------------------------------------------------
  // Atom
  // --------------------------------------------------------------------------
  const [lang] = useAtom(langAtom);
  // --------------------------------------------------------------------------
  // State
  // --------------------------------------------------------------------------
  const [isLoggedIn, setLoggedIn] = useState(false);
  // --------------------------------------------------------------------------
  // Etc
  // --------------------------------------------------------------------------
  const { t } = useTranslation('headerFooterMenu');
  const cookies = new Cookies();
  const isToken = cookies.get('token');
  const langComb = pathLang || lang;

  const isKorean = langComb === 'ko';
  const isJapanese = langComb === 'ja';

  const productHeadingText = t('Footer.ProductHeading', { lng: langComb });
  const companyHeadingText = t('Footer.CompanyHeading', { lng: langComb });
  const externalLinkHeadingText = t('Footer.ExternalLinkHeading', { lng: langComb });

  const correctTrialText = t('Footer.landing.correctTrial', { lng: langComb });
  const paraTrialText = t('Footer.landing.paraTrial', { lng: langComb });
  const translateTrialText = t('Footer.landing.translateTrial', { lng: langComb });
  const resumeText = t('Footer.landing.resume', { lng: langComb });
  const wordCounterText = t('Footer.landing.wordCounter', { lng: langComb });
  const pricingText = t('Footer.landing.pricing', { lng: langComb });

  const proofreadText = t('Footer.loggedIn.proofread', { lng: langComb });
  const paraphraseText = t('Footer.paraphrase', { lng: langComb });
  const translationText = t('Footer.loggedIn.translation', { lng: langComb });

  const loggedInLinkArr = [
    {
      name: proofreadText,
      link: '/proofread',
    },
    {
      name: paraphraseText,
      link: '/paraphrasing',
    },
    {
      name: translationText,
      link: '/translation',
    },
    {
      name: resumeText,
      link: getLangPath('/resume-builder', langComb),
    },
    {
      name: wordCounterText,
      link: getLangPath('/word-counter', langComb),
    },
    {
      name: pricingText,
      link: '/plan',
    },
  ];

  const landingLinkArr = [
    {
      name: correctTrialText,
      link: getLangPath('/grammar-check', langComb),
    },
    {
      name: paraTrialText,
      link: getLangPath('/paraphrase', langComb),
    },
    {
      name: translateTrialText,
      link: getLangPath('/translate', langComb),
    },
    {
      name: resumeText,
      link: getLangPath('/resume-builder', langComb),
    },
    {
      name: wordCounterText,
      link: getLangPath('/word-counter', langComb),
    },
    {
      name: pricingText,
      link: getLangPath('/pricing', langComb),
    },
  ];

  const productLinkArr = isLoggedIn ? loggedInLinkArr : landingLinkArr;

  const aboutUsText = t('Footer.aboutUs', { lng: langComb });
  const aboutUsLink = isLoggedIn ? '/company/about' : getLangPath('/about-us', langComb);

  const businessPageText = t('Footer.business', { lng: langComb });
  const businessPageLink = getLangPath('/business', langComb);
  const educationPageText = t('Footer.education', { lng: langComb });
  const educationPageLink = getLangPath('/education', langComb);

  const contactUsText = t('Footer.contactUs', { lng: langComb });

  const blogText = t('Footer.blog', { lng: langComb });
  const instaText = t('Footer.instagram', { lng: langComb });
  const youtubeText = t('Footer.youtube', { lng: langComb });

  const instaLink = t('Footer.instagramLink', { lng: langComb });
  const youtubeLink = t('Footer.youtubeLink', { lng: langComb });

  const termsLink = t('Footer.termsLink', { lng: langComb });
  const termsText = t('Footer.termsOfService', { lng: langComb });
  const policyLink = t('Footer.policyLink', { lng: langComb });
  const policyText = t('Footer.privacyPolicy', { lng: langComb });
  // --------------------------------------------------------------------------
  // Style
  // --------------------------------------------------------------------------
  const serviceHeadingStyle = `mb-16 text-bodyM font-bold leading-normal
    md:mb-16 md:text-bodyL lg:mb-24 lg:text-subhead`;

  const linkItemStyle = `h-24 mb-16 flex items-center text-bodyS font-medium
    last-of-type:mb-0 lg:text-bodyM lg:mb-24`;

  const linkIconStyle = 'w-24 h-24 mr-16 md:mr-8';
  /* ============================== 1. Hooks ============================== */
  useEffect(() => {
    setLoggedIn(isToken);
  }, [isToken]);

  return (
    <footer className="w-full bg-background">
      <div
        className={`w-full mx-auto px-24 flex flex-col justify-center
        items-center leading-none lg:w-1232 lg:px-0`}
      >
        {/* ===== 0. Navigation Link ===== */}
        <div
          className={`w-full w-min-312 flex justify-between py-16
          border-t border-dividerDark
          md:px-40 lg:px-[84px] lg:py-20`}
        >
          {/* ===== 0-0. Product Links ===== */}
          <div className="w-[138px] h-full lg:w-[158px]">
            <div className={serviceHeadingStyle}>
              {productHeadingText}
            </div>
            {productLinkArr.map((item: { name: string; link: string }) => (
              <Link
                key={item.name}
                href={item.link}
                className={linkItemStyle}
              >
                {item.name}
              </Link>
            ))}
          </div>
          {/* Company Links + External Links */}
          <div className="flex items-center">
            {/* ======= 0-1. Company Links Div ======= */}
            <div className="w-[124px] h-full lg:w-[142px]">
              <div className={serviceHeadingStyle}>{companyHeadingText}</div>
              <Link href={aboutUsLink} className={linkItemStyle}>
                {aboutUsText}
              </Link>
              <Link
                href={businessPageLink}
                className={linkItemStyle}
                dangerouslySetInnerHTML={{ __html: businessPageText }}
              />
              <Link
                href={educationPageLink}
                className={linkItemStyle}
                dangerouslySetInnerHTML={{ __html: educationPageText }}
              />
              <Link
                href="mailto:contact@engram.us"
                className={linkItemStyle}
              >
                {contactUsText}
              </Link>
              {isLoggedIn ? <Link href="/faq" className={linkItemStyle}>FAQ</Link> : null}
            </div>
            {/* ===== 0-2. External Links : ML_only ===== */}
            <div
              className={`ML_only w-[120px] h-full ml-[4px]
                md:ml-16 lg:ml-20`}
            >
              <div className={serviceHeadingStyle}>{externalLinkHeadingText}</div>
              <Link
                href={getBlogLink(lang)}
                target="_blank"
                className={linkItemStyle}
              >
                <img
                  src="/images/sns/ico-engram.svg"
                  alt="engram icon"
                  className={linkIconStyle}
                />
                {blogText}
              </Link>
              {isKorean && (
                <Link href="https://blog.naver.com/engram_blog" target="_blank" className={linkItemStyle}>
                  <img
                    src="/images/sns/ico-naverblog.svg"
                    alt="naver blog icon"
                    className={linkIconStyle}
                  />
                  네이버 블로그
                </Link>
              )}
              {!isJapanese && (
                <>
                  <Link href={instaLink} target="_blank" className={linkItemStyle}>
                    <img
                      src="/images/sns/ico-instagram.svg"
                      alt="instagram icon"
                      className={linkIconStyle}
                  />
                    {instaText}
                  </Link>
                  <Link href={youtubeLink} target="_blank" className={linkItemStyle}>
                    <img
                      src="/images/sns/ico-youtube.svg"
                      alt="youtube icon"
                      className={linkIconStyle}
                  />
                    {youtubeText}
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
        {/* ===== 0-3. External Links : S_only ===== */}
        <div className="S_only w-full mt-8 mb-16">
          <div className={serviceHeadingStyle}>{externalLinkHeadingText}</div>
          <div className="flex items-center">
            <Link href={getBlogLink(lang)} target="_blank">
              <img
                src="/images/sns/ico-engram.svg"
                alt="engram icon"
                className={linkIconStyle}
              />
            </Link>
            {isKorean && (
              <Link href="https://blog.naver.com/engram_blog" target="_blank">
                <IconBase size={24} src="/images/sns/ico-naverblog.svg" marginRight={8} />
              </Link>
            )}
            {!isJapanese && (
            <>
              <Link href={instaLink} target="_blank">
                <img
                  src="/images/sns/ico-instagram.svg"
                  alt="instagram icon"
                  className={linkIconStyle}
                  />
              </Link>
              <Link href={youtubeLink} target="_blank">
                <img
                  src="/images/sns/ico-youtube.svg"
                  alt="youtube icon"
                  className={linkIconStyle}
                  />
              </Link>
            </>
            )}
          </div>
        </div>
        {/* ===== 1. Company information ===== */}
        <div className={`w-full min-w-312 pt-24 pb-16 flex flex-col
          justify-between items-center border-t border-dividerLight
          md:py-16 lg:py-20`}>
          {/* ===== 1-0. Terms of Service, privacy policy links ===== */}
          <div className={`w-full flex justify-between flex-col items-center
            md:mb-16 md:flex-row lg:mb-20`}>
            <div className="flex items-center mb-24 md:mb-0">
              <Link
                href={termsLink}
                target="_blank"
                className={`inline-block pr-[8px] text-captionL font-medium
                  text-block border-r border-dividerDark
                  md:pr-[16px] lg:pr-[20px] lg:text-bodyS`}
              >
                {termsText}
              </Link>
              <Link
                href={policyLink}
                target="_blank"
                className={`inline-block px-[8px] text-captionL font-medium
                  text-block border-r border-dividerDark
                  md:px-[16px] lg:px-[20px] lg:text-bodyS `}
              >
                {policyText}
              </Link>
              <a
                href="mailto:contact@engram.us"
                target="_blank"
                rel="noreferrer"
                className={`inline-block pl-[8px] text-captionL font-medium text-block
                  md:pl-[16px] lg:pl-[20px] lg:text-bodyS`}
              >
                contact@engram.us
              </a>
            </div>
            {/* Language Selector */}
            <LangSelect />
          </div>
          <div className={`w-full mt-16 text-captionM leading-normal 
            text-center text-darkGray md:text-captionL md:mt-0`}>
            {isKorean ? (
              <>
                대표: 오승현 | 상호명: 주식회사 데이터비
                <span className="ML_only"> | </span>
                <br className="S_only" />
                사업자등록번호: 164-86-01011 | 통신판매업신고번호: 2024-서울서초-1806
                <br />
                주소: 서울시 서초구 매헌로 8길 47 B동 203호 (06770)
                <span className="ML_only"> | </span>
                <br className="S_only" />
                전화번호: 070-4422-2875
                <br />
                ⓒ Data B Co., Ltd.
              </>
            ) : (
              <>
                ⓒ 2024 Data B USA, Inc.
              </>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
