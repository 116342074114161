import { atom } from 'jotai';

export interface CorrectionTypes {
  correctionId: number;
  sugHtml: string;
  afterHtml: string;
}

export interface AcceptedCorrectionTypes {
  id: number;
  after: string;
  status: 'default' | 'ignored' | 'accepted' | 'deleted';
}

export type SentenceTypes = {
  senId: number;
  status: number;
  beforeHtml: string;
  acceptedCorrection?: AcceptedCorrectionTypes;
  is_deleted?: boolean;
  corrections: CorrectionTypes[];
}

export interface CorrectTypes {
  id: number;
  originalText: string;
  sentences: (SentenceTypes | '<br />')[];
  isSaveFeatureNotified: boolean;
  isKeyboardFeatureNotified?: boolean;
}

export const emptyCorrect: CorrectTypes = {
  id: 0,
  originalText: '',
  sentences: [
    {
      senId: 0,
      status: 0,
      beforeHtml: '',
      acceptedCorrection: { id: 0, after: '', status: 'default' },
      is_deleted: false,
      corrections: [
        {
          correctionId: 0,
          sugHtml: '',
          afterHtml: '',
        },
      ],
    },
    '<br />',
  ],
  isSaveFeatureNotified: false,
};

export default atom<CorrectTypes>(emptyCorrect);
