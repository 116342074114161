import Cookies from 'universal-cookie';
//prettier-ignore

// takes in string and returns default Product Path
export const getProductPath = (productString: string) => {
  return productString === 'paraphrase'
    ? '/paraphrasing'
    : productString === 'translate'
      ? '/translation'
      : '/proofread';
};

// get default product path by taking in cookie value
export const getDefaultProduct = () => {
  const cookies = new Cookies();
  const defaultProduct = cookies.get('default_product');

  return getProductPath(defaultProduct);
};
