import { useEffect, useState } from 'react';
import Link from 'next/link';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import planStatusAtom from 'stores/planStatusAtom';
import { useRouter } from 'next/router';
import useFetch from 'net/useFetch';
import { SwrContainer } from 'components/containers';
import { Logo } from 'components/images';
import useWindowSize from 'hooks/useWindowSize';
import { RESPONSIVE_SIZE } from 'constants/RESPONSIVE_SIZE';
import { getDefaultProduct } from 'utils/getDefaultProduct';
import { PlanStatusButton } from './buttons';
import { Menu } from './Menu';

type HeaderProps = {
  correctPage?: boolean;
  isRealTime?: boolean;
  isIosLessThan16?: boolean;
};

const PlanStatusLoading = () => {
  return (
    <div className="w-56 h-24 ml-8 md:w-[68px]" />
  );
};

const UserIconLoading = () => {
  return (
    <div
      className={`w-56 h-32 ml-8 [&_div]:py-[11px]
        lg:w-[68px] lg:h-[44px] [&_div]:lg:py-[17px]`}
    >
      <div className="dot-stage">
        <span className="dot-pulse" />
      </div>
    </div>
  );
};

export const Header = ({ correctPage, isRealTime, isIosLessThan16 }: HeaderProps) => {
  // ======================================== VARIABLES ====================================================
  // --------------------------------------------------------------------------
  // Atom
  // --------------------------------------------------------------------------
  const [, setPlanStatus] = useAtom(planStatusAtom);
  // --------------------------------------------------------------------------
  // State
  // --------------------------------------------------------------------------
  // decides whether menu is open or not
  const [showMenu, setShowMenu] = useState(false);
  // checks if window width is currently larger than 900px
  const [isDesktop, setIsDesktop] = useState<boolean>();
  // --------------------------------------------------------------------------
  // Etc
  // --------------------------------------------------------------------------
  const { t } = useTranslation('headerFooterMenu');
  const router = useRouter();
  const { pathname } = router;
  const cookies = new Cookies();
  // fetch and cache plan status data
  const { data, error } = useFetch(`${process.env.API_HOST}/info/v4`);
  const size = useWindowSize();

  const defaultProduct = cookies.get('default_product');

  const proofread = t('menu.Proofread');
  const paraphrasing = t('menu.Paraphrase');
  const translation = t('menu.Translation');

  const isCorrectPage = pathname === '/proofread' || pathname === '/proofread-mb';
  const isParaphrasingPage = pathname.startsWith('/paraphrasing');
  const isTranslationPage = pathname === '/translation';

  // --------------------------------------------------------------------------
  // Style
  // --------------------------------------------------------------------------
  let zIndexStyle = '';

  if (showMenu) {
    zIndexStyle = 'z-[199]';
  } else if (!isIosLessThan16) {
    zIndexStyle = 'z-[100]';
  }

  const prodLinkCommonStyle = (isOrangeText: boolean) => {
    const fontWeight = isOrangeText ? 'font-bold' : 'font-medium';
    const textColor = isOrangeText ? 'text-primary' : 'text-gray';
    return `text-bodyS lg:text-bodyM ${fontWeight} ${textColor}`;
  };

  // ====================================== HOOKS ======================================================
  // update planStatus when there's a data after fetching.
  useEffect(() => {
    if (data) {
      setPlanStatus(data);

      if (data.setting?.default_product !== defaultProduct) {
        const cookieMaxAge = Number(process.env.COOKIE_MAX_AGE);
        cookies.set('default_product', data.setting.default_product, { path: '/', maxAge: cookieMaxAge });
      }
    }
  }, [data]);

  // updates isDesktop whenever window width changes.
  useEffect(() => {
    const upTablet = window.matchMedia(`(min-width: ${RESPONSIVE_SIZE.S_UB}px)`);
    if (upTablet.matches) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [size]);

  return (
    <header
      className={`w-full min-w-312 fixed top-0 left-0 right-0
        bg-background shadow-bar ${zIndexStyle}`}
    >
      <div
        className={`max-w-1232 h-48 mx-auto px-24 flex items-center
          justify-between bg-surface
          md:w-[calc(100%-48px)] md:h-64 md:mx-24 md:px-0 md:bg-background
          lg:w-full lg:h-64 lg:mx-auto`}
      >
        {/* logo and planStatus Button doesn't show on mobile version correctPage Header */}
        {(!correctPage || isRealTime || isDesktop) && (
          <div className="flex items-center">
            <Logo
              className="w-[74px] md:w-[92px] lg:w-[110px]"
              href={getDefaultProduct()}
            />
            <SwrContainer data={isDesktop !== undefined && data} error={error} loadingIndicator={<PlanStatusLoading />}>
              <PlanStatusButton />
            </SwrContainer>
          </div>
        )}
        <SwrContainer data={isDesktop !== undefined && data} error={error} loadingIndicator={<UserIconLoading />}>
          <div className="flex items-center">
            <Link
              className={`ML_only ${prodLinkCommonStyle(isCorrectPage)}`}
              href="/proofread"
            >
              {proofread}
            </Link>
            <Link
              className={`ML_only ${prodLinkCommonStyle(isParaphrasingPage)}
              mx-24 lg:mx-32`}
              href="/paraphrasing"
            >
              {paraphrasing}
            </Link>
            <Link
              className={`ML_only ${prodLinkCommonStyle(isTranslationPage)}
              mr-24 lg:mr-32`}
              href="/translation"
            >
              {translation}
            </Link>
            <Menu showMenu={showMenu} setShowMenu={setShowMenu} isDesktop={isDesktop} />
          </div>
        </SwrContainer>
      </div>
    </header>
  );
};
