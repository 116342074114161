import { useState, useEffect } from 'react';

const debounce = (fn: () => void, ms: number) => {
  let timerId: ReturnType<typeof setTimeout> | null | undefined | string | number;
  return (...args: []) => {
    if (typeof timerId === 'number' || typeof timerId === 'function' || typeof timerId === 'undefined') {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      timerId = null;
      fn.apply(this, args);
    }, ms);
  };
};

export default function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    // delay execution of setSize unless window.innerWidth or window.innerHeight has changed,
    // and ensure setSize is called once every second maximum.
    const debouncedHandleResize = debounce(() => {
      setSize([window.innerWidth, window.innerHeight]);
    }, 10);

    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);
  return size;
}
