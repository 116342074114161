import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { Icon } from 'components/images';
import { FONT_SIZE } from 'constants/FONT_SIZE';
import { COLOR } from 'constants/COLOR';
import { RADIUS } from 'constants/RADIUS';
import { getInAppBrowserType, InAppBrowserType } from 'utils/getInAppBrowserType';

const Component = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  height: fit-content;
  background-color: ${COLOR.BACKGROUND};
`;

const Content = styled.div`
  max-width: 312px;
  margin: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  margin-bottom: 24px;
  text-align: center;
  line-height: 1.5;
  font-size: ${FONT_SIZE.bodyL}px;
  font-weight: bold;
`;

const StepWrapper = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${RADIUS.MB}px;
  border: 1px solid ${COLOR.OUTLINE};
  background-color: ${COLOR.SURFACE};
`;

const ImgMarginWrap = styled.div<{ margin: string }>`
  margin: ${({ margin }) => margin || '0px'};
`;

const Chip = styled.div`
  width: 56px;
  height: fit-content;
  padding: 6px 0;
  margin-bottom: 24px;
  color: ${COLOR.SURFACE};
  text-align: center;
  font-weight: bold;
  font-size: ${FONT_SIZE.captionL}px;
  background-color: ${COLOR.PRIMARY};
  border-radius: ${RADIUS.MB}px;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.5;
  font-size: ${FONT_SIZE.bodyS}px;
  .bold {
    font-weight: bold;
  }
`;

const Divider = styled.div`
  width: 100%;
  margin: 16px 0;
  display: flex;
  align-items: center;
  hr {
    width: 50%;
    height: 1px;
    outline: none;
    border: none;
    background-color: ${COLOR.DIVIDER_LIGHT};
  }
  div.text {
    margin: 0 12px;
    font-size: ${FONT_SIZE.captionL}px;
  }
`;

export const InAppBrowserNotice = () => {
  const [inAppBrowserType, setInAppBrowserType] = useState<InAppBrowserType>(undefined);

  const isNaver = inAppBrowserType === 'naver';

  useEffect(() => {
    const tmpInAppBrowserType = getInAppBrowserType(navigator.userAgent);
    setInAppBrowserType(tmpInAppBrowserType);
  }, []);

  return (
    <Component>
      {/* Content That should be shown when user is accessing page from Naver, Kakao In app browser */}
      <Content>
        {/* 0. Title */}
        <Title>
          원활한 로그인을 위해
          <br />
          아래 방법으로 접속해주세요 (3초 소요)
        </Title>
        {/* 1. Step 1 */}
        <StepWrapper>
          <Chip>STEP 1</Chip>
          {isNaver ? (
            <>
              {/* 1-0. Step 1 - Naver */}
              <Text>
                하단의
                <ImgMarginWrap margin="0 8px">
                  <Icon size={24} src="/images/in-app-browser/ico-naver-hamburger.svg" />
                </ImgMarginWrap>
                아이콘을 선택해주세요
              </Text>
              <ImgMarginWrap margin="16px 0 0 0">
                <Image
                  width={228}
                  height={32}
                  src="/images/in-app-browser/ico-naver-navigation.png"
                  alt="네이버 앱의 하단 네비게이션 바. 가장 우측의 햄버거 메뉴가 강조되어 있다."
                />
              </ImgMarginWrap>
            </>
          ) : (
            <>
              {/* 1-1. Step 1 - Kakao */}
              <Text>
                하단의
                <ImgMarginWrap margin="0 8px">
                  <Icon src="/images/in-app-browser/ico-kakao-export.svg" size={24} />
                </ImgMarginWrap>
                아이콘을 선택하거나
              </Text>
              <ImgMarginWrap margin="16px 0 24px 0">
                <Image
                  width={230}
                  height={32}
                  src="/images/in-app-browser/ico-kakao-ios-nav.png"
                  alt="네이버 앱의 하단 네비게이션 바. 가장 우측의 햄버거 메뉴가 강조되어 있다."
                />
              </ImgMarginWrap>
              <Text>
                하단 또는 상단의
                <ImgMarginWrap margin="0 8px">
                  <Icon src="/images/in-app-browser/ico-kakao-kebab.svg" size={24} />
                </ImgMarginWrap>
                아이콘을 선택한 후에
              </Text>
              <ImgMarginWrap margin="16px 0 0 0">
                <Image
                  width={230}
                  height={55}
                  src="/images/in-app-browser/ico-kakao-android-nav.png"
                  alt="네이버 앱의 하단 네비게이션 바. 가장 우측의 햄버거 메뉴가 강조되어 있다."
                />
              </ImgMarginWrap>
            </>
          )}
        </StepWrapper>
        <ImgMarginWrap margin="8px 0">
          <Icon src="/images/landing2/ico-drop-down.svg" size={24} />
        </ImgMarginWrap>
        <StepWrapper>
          {/* 2. Step 2 */}
          <Chip>STEP 2</Chip>
          {isNaver ? (
            <>
              {/* 2-0. Step 2 - Naver */}
              <Text>
                <span className="bold">기본 브라우저로 열기</span>
                를 선택해주세요
              </Text>
              <ImgMarginWrap margin="16px 0 0 0">
                <Image
                  width={224}
                  height={178}
                  src="/images/in-app-browser/ico-naver-float.png"
                  alt="네이버 하단의 네비게이션 바의 햄버거 버튼을 누르면 나오는 메뉴들. 기본 브라우저로 열기 라는 메뉴가 강조되어 있다."
                />
              </ImgMarginWrap>
            </>
          ) : (
            <>
              {/* 2-1. Step 2 - Kakao */}
              <Text>
                <span className="bold">Safari로 열기</span>
                를 선택해주세요
              </Text>
              <ImgMarginWrap margin="16px 0 0 0">
                <Image
                  width={224}
                  height={66}
                  src="/images/in-app-browser/ico-kakao-iso-menu.png"
                  alt="네이버 하단의 네비게이션 바의 햄버거 버튼을 누르면 나오는 메뉴들. 기본 브라우저로 열기 라는 메뉴가 강조되어 있다."
                />
              </ImgMarginWrap>
              <Divider>
                <hr />
                <div className="text">or</div>
                <hr />
              </Divider>
              <Text>
                <span className="bold">다른 브라우저로 열기</span>
                를 선택해주세요
              </Text>
              <ImgMarginWrap margin="16px 0 0 0">
                <Image
                  width={112}
                  height={86}
                  src="/images/in-app-browser/ico-kakao-android-menu.png"
                  alt="네이버 하단의 네비게이션 바의 햄버거 버튼을 누르면 나오는 메뉴들. 기본 브라우저로 열기 라는 메뉴가 강조되어 있다."
                />
              </ImgMarginWrap>
            </>
          )}
        </StepWrapper>
      </Content>
    </Component>
  );
};
